import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Report from '../../common/Report';
import EntityCriteriaSelect from '../../common/EntityCriteriaSelect';

class ContactList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var allColumns = [
            { label: "First Name", fieldName: "firstName", type: "standard" },
            { label: "Last Name", fieldName: "lastName", type: "standard" },
            { label: "Email", fieldName: "email", type: "standard" },
            { label: "Phone Number", fieldName: "phoneNumberStr", type: "standard" },
            { label: "Fax Number", fieldName: "faxNumberStr", type: "standard" },
            { label: "Account Name", fieldName: "accountName", type: "standard" },
            { label: "State", fieldName: "state", type: "standard" },
            { label: "City", fieldName: "city", type: "standard" },
            { label: "Zip Code", fieldName: "zipCode", type: "standard" },
        ];

        this.filterCriterias = [
            { label: "First Name", name: "first_name", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "Last Name", name: "last_name", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "Email", name: "email", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "Phone Number", name: "phone_number", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "Account Name", name: "account_name", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "State", name: "state", type: "standard", operators: ["equal"], selector: "StateSelect" },
            { label: "City", name: "city", type: "standard", operators: ["equal", "like", "starts with"] },
            { label: "Zip Code", name: "zip_code", type: "standard", operators: ["equal", "like", "starts with"] },
        ];

        var selectedColumns = allColumns.slice(0, 3);

        var initialState = {
            criterias: [{ fieldName: "first_name", operator: "like", value: "" }],
            contacts: [],
            selectedColumns: selectedColumns,
            allColumns: allColumns,
            reportReady: false,
        }
        this.sortBy = "";
        this.sortDirection = "ASC";
        this.paramsKey = "params.contact-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleColumnsSelected = this.handleColumnsSelected.bind(this);
        this.handleReportDataLoaded = this.handleReportDataLoaded.bind(this);
        this.addCriteria = this.addCriteria.bind(this);
        this.removeCriteria = this.removeCriteria.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.cancelSearch = this.cancelSearch.bind(this);
    }

    componentDidMount() {
        this.getUserDefinedFields();
    }

    getUserDefinedFields() {
        var params = {
            entity: "Contact"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            var allColumns = this.state.allColumns;
            for (var i = 0; i < data.length; i++) {
                allColumns.push({
                    label: data[i].name,
                    fieldName: data[i].userDefinedFieldID.toString(),
                    type: "user_defined"
                });
            }

            this.setState({
                allColumns: allColumns
            });

            if (this.props.match.params.reportId) {
                var params = {
                    id: this.props.match.params.reportId
                }
                this.http.get('/api/Report/GetReportData', params).then(data => {
                    this.handleReportDataLoaded(data.params);
                    this.setState({
                        saveAs: data.reportName,
                        reportReady: true
                    });
                });
            }
            else {
                this.setState({
                    reportReady: true
                });
            }
        });
    }

    handleCriteriaChange(index, e) {
        var crts = this.state.criterias;
        var values = [];
        var value = e.value;
        if (Array.isArray(e.value)) {
            values = e.value;
        }
        crts[index] = {
            fieldName: e.field.name,
            operator: e.operator,
            value: value,
            values: values
        }
        this.setState({
            criterias: crts
        })
    }

    handleColumnsSelected(e) {
        var selectedColumns = [];
        for (var i = 0; i < e.length; i++) {
            selectedColumns.push(this.state.allColumns.find(c => c.fieldName === e[i].fieldName));
        }
        this.setState({
            selectedColumns: selectedColumns
        });
    }

    addCriteria() {
        var crts = this.state.criterias;
        crts.push({ fieldName: "first_name", operator: "like", value: "" });
        this.setState({
            criterias: crts
        });
    }

    removeCriteria(index, e) {
        var crts = this.state.criterias;
        crts.splice(index, 1);
        this.setState({
            criterias: crts
        });
        this.forceUpdate();
    }

    getContacts(e, fetchManyRecords = false) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            filter: JSON.stringify(this.state.criterias),
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            fetchManyRecords: fetchManyRecords
        }
        // var sessionState = {
        //     criterias: this.state.criterias,
        //     selectedColumns: this.state.selectedColumns
        // }
        // sessionStorage.setItem(this.paramsKey, JSON.stringify(sessionState));
        this.setState({ loading: true });
        this.http.get('/api/Crm/SearchContacts', params, false, this.cancelSearch).then(data => {
            this.setState({ loading: false });
            if (data.tooManyRecords) {
                this.utils.confirm("Your search will return " + data.count + " records and will take awhile, you may want to narrow your search.", null, () => {
                    this.getContacts(e, true);
                }, "Refine Search", "Submit Current Search");
            }
            else {
                this.setState({ contacts: data });
            }
        }).catch(() => {
            this.setState({ loading: false });
        });
    }

    cancelSearch() {
        this.http.cancel();
    }

    getFieldValue(contact, column) {
        if (column.type === "standard") {
            return contact[column.fieldName];
        }
        else {
            var userDefinedField = contact.userDefinedFields.find(f => f.userDefinedFieldID === parseInt(column.fieldName));
            if (userDefinedField) {
                return userDefinedField.valueStr;
            }
        }
        return "";
    }

    getSaveParams() {
        return {
            criterias: this.state.criterias,
            selectedColumns: this.state.selectedColumns
        }
    }

    handleReportDataLoaded(params) {
        var data = JSON.parse(params);
        this.setState({
            criterias: data.criterias,
            selectedColumns: data.selectedColumns,
        }, this.getContacts)
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getContacts();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Contact List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <form onSubmit={(e) => this.getContacts(e)}>
                                <div className="card-body">
                                    <div className="form-row">
                                        {this.state.criterias.map((criteria, index) =>
                                            <React.Fragment key={index}>
                                                <div className="form-group col-md-9">
                                                    <div className="form-row">
                                                        <EntityCriteriaSelect entity="Contact" fields={this.filterCriterias} criteria={criteria} onChange={(e) => this.handleCriteriaChange(index, e)}></EntityCriteriaSelect>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-3 mt-2">
                                                    {this.state.criterias.length > 1 &&
                                                        <React.Fragment>
                                                            <button type="button" className="btn btn-sm mt-4" onClick={(e) => this.removeCriteria(index, e)} style={{ marginRight: 4 }}><i className="fas fa-times"></i></button>
                                                        </React.Fragment>

                                                    }
                                                    {index === this.state.criterias.length - 1 &&
                                                        <button type="button" className="btn btn-sm mt-4" onClick={this.addCriteria}>Add Criteria</button>
                                                    }
                                                </div>

                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            {!this.state.loading &&
                                                <button type="submit" className="btn btn-primary pull-right">Submit</button>
                                            }
                                            {this.state.loading &&
                                                <button type="button" className="btn btn-primary pull-right" onClick={this.cancelSearch}>Cancel Search</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                {this.state.reportReady &&
                                    <Report name="ContactList" hasData={this.state.contacts && this.state.contacts.length > 0}
                                        allowColumnSelection={true}
                                        availableColumns={this.state.allColumns}
                                        onColumnsSelected={this.handleColumnsSelected}
                                        allowSave={true}
                                        saveAs={this.state.saveAs}
                                        saveParams={this.getSaveParams()}
                                        saveAsType="ContactList"
                                        onReportDataLoad={this.handleReportDataLoaded}
                                    >
                                        <div className="form-row">
                                            <table className="table table-striped mt-3">
                                                <thead>
                                                    <tr>
                                                        {this.state.selectedColumns.length > 0 && this.state.selectedColumns.map(column =>
                                                            <th key={column.fieldName} scope="col" className="sortable" onClick={(e) => this.sortReport(column.fieldName)}>{column.label}</th>
                                                        )}
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.contacts.map((contact) =>
                                                        <tr key={contact.contactID}>
                                                            {this.state.selectedColumns.length > 0 && this.state.selectedColumns.map((column, index) =>
                                                                <td key={column.fieldName}>
                                                                    {index === 0 &&
                                                                        <Link to={"/crm/contact/" + contact.contactID}><span dangerouslySetInnerHTML={{ __html: this.getFieldValue(contact, column) }}></span></Link>
                                                                    }
                                                                    {index !== 0 &&
                                                                        <span dangerouslySetInnerHTML={{ __html: this.getFieldValue(contact, column) }}></span>
                                                                    }
                                                                </td>
                                                            )}
                                                            <td></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Report>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ContactList;
