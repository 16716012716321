import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import ProductInformation from './ProductInformation';

class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            productInformation: {}
        };
        this.handleProductInfoChange = this.handleProductInfoChange.bind(this);
        this.createProduct = this.createProduct.bind(this);
    }

    handleProductInfoChange(value) {
        this.setState({
            productInformation: value
        })
    }

    createProduct() {
        this.http.get('/api/Inventory/CreateProduct', { model: this.state.productInformation }).then(data => {
            this.props.history.push('/inventory/product/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add Product</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">Product Information</div>
                            </div>
                            <div className="card-body">
                                <ProductInformation onChange={this.handleProductInfoChange}></ProductInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createProduct}>Create Product</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddProduct;
