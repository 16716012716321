import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ShoppingCart from './ShoppingCart'
import ShipTypeSelect from '../../common/ShipTypeSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import { UtilsService } from '../../services/UtilsService';
import CustomerInfoCard from '../customers/CustomerInfoCard';
import Modal from '../../common/Modal';
import ComposeEmail from '../../common/ComposeEmail';
import { Link } from "react-router-dom";
import ShipmentDetails from './ShipmentDetails';

class FulfillOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      customer: {},
      shippingNote: "",
      invoiceEmailBody: "",
    };
    this.http = new HttpRequestService();
    this.utils = new UtilsService();
    this.beforeFulfillOrder = this.beforeFulfillOrder.bind(this);
    this.fulfillOrder = this.fulfillOrder.bind(this);
    this.sendInvoiceMail = this.sendInvoiceMail.bind(this);
    this.handleShippedChange = this.handleShippedChange.bind(this);
    this.handleInvoiceMailChange = this.handleInvoiceMailChange.bind(this);
    this.handleShippingNoteChange = this.handleShippingNoteChange.bind(this);
    this.handleShipTypeChange = this.handleShipTypeChange.bind(this);
    this.handleShipmentDetailsChange = this.handleShipmentDetailsChange.bind(this);
    this.submitShipmentDetails = this.submitShipmentDetails.bind(this);
  }

  componentDidMount() {
    this.getOrder();
    this.getInvoiceMailBody();
  }

  componentWillUnmount() {
  }

  handleShippedChange(cartItem) {
    var sortedProductItems = this.state.order.shoppingCart.sortedNutraCartProductItems;
    for (var key in sortedProductItems) {
      if (key === cartItem.productID) {
        sortedProductItems[key].quantityShipped = cartItem.quantityShipped;
        break;
      }
    }
    this.forceUpdate();
  }

  handleShippingNoteChange(e) {
    this.setState({
      shippingNote: e.target.value
    })
  }

  handleShipTypeChange(value) {
    var params = {
      orderId: this.state.order.orderID,
      shipTypeId: value.shipTypeID
    }
    this.http.get('/api/Orders/AlterShipping', params).then(data => {
      this.updateState(data);
    })
  }

  handleShipmentDetailsChange(value) {
    this.shipmentDetails = value;
  }

  getOrder() {
    var params = {
      orderId: this.props.match.params.id
    }
    this.http.get('/api/Orders/GetOrder', params).then(data => {
      this.getSmtpAccountId();
      this.updateState(data);
    })
  }

  getSmtpAccountId() {
    this.http.getSilent('/api/Admin/GetCompanyEmailSendingAccounts', null).then(data => {
      const account = data.find(a => a.companyId == this.state.order.company.key);
      this.setState({
        smtpAccountId: account.smtpAccountId
      })
    });
  }

  getInvoiceMailBody() {
    this.http.get('/api/Admin/GetSettings').then(data => {
      var invoiceEmailBody = "";
      if (data.find(s => s.name === "InvoiceEmailBody")) {
        invoiceEmailBody = data.find(s => s.name === "InvoiceEmailBody").value;
      }
      this.setState({
        invoiceEmailBody: invoiceEmailBody,
      });
    })
  }

  getCartItems(shoppingCart) {
    if (!!shoppingCart) {
      var sortedProductItems = shoppingCart.sortedNutraCartProductItems;
      var cartItems = [];
      for (var key in sortedProductItems) {
        var item = sortedProductItems[key];
        item.id = key;
        cartItems.push(item);
      }
      return cartItems;
    }
    else {
      return [];
    }
  }

  beforeFulfillOrder() {
    this.http.get('/api/Orders/ASNRequired', { customerId: this.state.order.customer.customerID, orderId: this.state.order.orderID }).then(data => {
      if (data && this.state.order.total >= 0) {
        window.$('#shipmentDetailsModal').modal('show');
      }
      else {
        this.fulfillOrder();
      }
    })
  }

  prepareFulfillmentModel() {
    var shippedQuantities = [];
    var sortedProductItems = this.state.order.shoppingCart.sortedNutraCartProductItems;
    for (var key in sortedProductItems) {
      var item = sortedProductItems[key];
      item.id = key;
      shippedQuantities.push({ productId: item.productID, quantity: item.quantityShipped, orderedQuantity: item.quantity, cartItemId: item.id });
    }
    var model = {
      orderId: this.state.order.orderID,
      shippedQuantities: shippedQuantities,
      shippingNote: this.state.shippingNote
    }

    return model;
  }

  fulfillOrder() {
    var params = this.prepareFulfillmentModel();
    this.http.get('/api/Orders/FulfillOrder', { model: params }).then(data => {
      this.utils.success("").then(() => {
        this.props.history.push("/fulfillment-paperless");
      });

      if (this.state.printPackingSlip) {
        window.open(this.getPrintPDFUrl("PackingSlip"), "_blank");
      }

      if (this.state.printInvoice) {
        window.open(this.getPrintPDFUrl("Invoice", "_blank"));
      }
    })
  }

  submitShipmentDetails() {

    var fulfillModel = this.prepareFulfillmentModel();

    var params = {
      fulfillDetails: fulfillModel,
      shipmentDetails: this.shipmentDetails
    }

    this.http.post('/api/Orders/SetASNDetailsAndFulfillOrder', params).then(data => {
      this.utils.success("");
      this.props.history.push("/fulfillment");
      if (this.state.printPackingSlip) {
        window.open(this.getPrintPDFUrl("PackingSlip"), "_blank");
      }
      if (this.state.printInvoice) {
        window.open(this.getPrintPDFUrl("Invoice", "_blank"));
      }
      if (this.shipmentDetails.printUCCLabel) {
        window.open(this.getPrintUCCLabelUrl("PackingSlip"), "_blank");
      }
      if (this.state.order.standardComment == null || this.state.order.standardComment.key == null || this.state.order.standardComment.key !== 4) {
        // print invoice if this is not a drop ship order
        window.open(this.getPrintPDFUrl("Invoice"), "_blank");
      }
    })
  }

  fulfillmentButtonVisible() {
    var order = this.state.order;
    return order.isValid && order.orderStatusText === "Open";
  }

  handleInvoiceMailChange(mail) {
    this.invoiceMail = mail;
  }

  sendInvoiceMail() {
    if (this.invoiceMail) {
      var model = this.invoiceMail;
      model.orderId = this.state.order.orderID;
      this.http.get('/api/Orders/SendInvoiceMail', { model: model }).then(data => {
        this.utils.success("");
        window.$('#sendInvoiceModal').modal('hide');
      });
    }
    else {
      window.$('#sendInvoiceModal').modal('hide');
    }
  }

  getPrintPDFUrl(type) {
    return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + this.state.order.orderID + "&OrderNumber=" + this.state.order.orderNumber;
  }

  getPrintUCCLabelUrl() {
    return this.http.HOST + "/Print/UCCLabel?OrderId=" + this.state.order.orderID + "&OrderNumber=" + this.state.order.orderNumber;
  }

  getEmailSubject() {
    let subject = this.state.order.company ? this.state.order.company.value + " Invoice #" + this.state.order.orderNumber : "";
    if (this.state.order.po !== null && this.state.order.po !== "") {
      subject += " / PO #" + this.state.order.po;
    }
    return subject;
  }

  updateState(data) {
    this.setState({
      order: data,
      customer: data.customer,
      printPackingSlip: data.standardComment && data.standardComment.value === "Print Packing Slip Only",
      printInvoice: (data.standardComment && data.standardComment.value === "Print Invoice Only") || (data.customer && data.customer.autoPrintInvoices)
    });
  }

  render() {
    return (
      <div className="page-inner">
        <div className="page-header">
          <h4 className="page-title">Fulfill Order</h4>
        </div>
        {/* Rpw 1 */}
        <div className="row">
          {/* Customer Information */}
          <div className="col-md-3">
            <CustomerInfoCard customer={this.state.customer}></CustomerInfoCard>
          </div>
          {/* End Customer Information */}
          <div className="col-md-9">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Order Information</div>
                  <div className="card-tools">
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('SalesOrder')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      SALES ORDER
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('PackingSlip')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      PACKING SLIP
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintUCCLabelUrl()} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      UCC LABEL
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('Invoice')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      INVOICE
                    </a>
                    <button className="btn btn-info btn-border btn-round btn-sm" data-toggle="modal" data-target="#sendInvoiceModal">
                      <span className="btn-label">
                        <i className="fas fa-envelope"></i>
                      </span>
                      EMAIL INVOICE
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">ORDER #</h6>
                    <small className="text-muted"><Link to={"/order/" + this.state.order.orderID}>{this.state.order.orderNumber}</Link></small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">ORDER DATE</h6>
                    <small className="text-muted">{moment(this.state.order.orderDate).format('l')}</small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">ACCOUNT TYPE</h6>
                    <small className="text-muted">{this.state.order.salesman != null ? this.state.order.salesman.value : ""} {this.state.order.salesmanInitials}</small>
                  </div>
                </div>
                <div className="separator-dashed"></div>
                <div className="d-flex">
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">COMPANY</h6>
                    <small className="text-muted">{this.state.order.company != null ? this.state.order.company.value : ""}</small>
                  </div>
                  <div className="col-8 pt-1">
                    <h6 className="fw-bold mb-1">ORDER NOTES</h6>
                    <small className="text-muted">{this.state.order.standardComment != null && this.state.order.standardComment.key != null ? this.state.order.standardComment.value + " " + this.state.order.notes : this.state.order.notes}</small>
                  </div>
                </div>
                <div className="separator-dashed"></div>
                <div className="d-flex">
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">CUSTOMER PO</h6>
                    <small className="text-muted">{this.state.order.po}</small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">PAYMENT TYPE</h6>
                    <small className="text-muted">{this.state.paymentType != null ? this.state.paymentType.description : ""}</small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">SHIPPING METHOD</h6>
                    <small className="text-muted">{this.state.order.orderShipment != null && this.state.order.orderShipment.shipType != null ? this.state.order.orderShipment.shipType.description : ""}</small>
                  </div>
                </div>
                <div className="separator-dashed"></div>
                <div className="d-flex">
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">CURRENT STATUS</h6>
                    <small className="text-muted">{this.state.order.orderStatusText}</small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">SITE</h6>
                    <small className="text-muted">{this.state.order.site != null ? this.state.order.site.value : ""}</small>
                  </div>
                  <div className="col pt-1">
                    <h6 className="fw-bold mb-1">PARENT ORDER #</h6>
                    <small className="text-muted">{this.state.order.parentOrder != null ? this.state.order.parentOrder.orderNumber : ""}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Row 1*/}
        {/* Row 2 */}
        <div className="row">
          {/* Products */}
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Products</div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-9">
                    <label>Shipping Note / Instructions</label>
                    <input type="text" value={this.state.shippingNote} onChange={this.handleShippingNoteChange} className="form-control" />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Alternate Shipping</label>
                    <ShipTypeSelect placeholder="-- OPTIONAL --" value={this.state.shipType} onChange={this.handleShipTypeChange}></ShipTypeSelect>
                  </div>
                </div>
                {this.state.order.shoppingCart != null &&
                  <ShoppingCart fulfillment={true} order={this.state.order} shoppingCart={this.state.order.shoppingCart} onShippedChange={this.handleShippedChange}></ShoppingCart>
                }
              </div>
            </div>
          </div>
          {/* End Products */}
        </div>
        {/* End Row 2 */}
        {/* Row 3 */}
        <div className="row">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-body pull-right">
                {this.fulfillmentButtonVisible() &&
                  <React.Fragment>
                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.beforeFulfillOrder}>Fulfill and Complete Order</button>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        {/* End Row 3 */}
        <Modal id="sendInvoiceModal" title="Email Invoice" onOk={this.sendInvoiceMail}>
          <ComposeEmail smtpAccountId={this.state.smtpAccountId} to={this.state.customer.invoiceEmailAddresses} onChange={this.handleInvoiceMailChange} subject={this.getEmailSubject()} body={this.state.invoiceEmailBody}></ComposeEmail>
        </Modal>
        <Modal id="shipmentDetailsModal" title="Shipment Details" onOk={this.submitShipmentDetails}>
          <ShipmentDetails onChange={this.handleShipmentDetailsChange} order={this.state.order}></ShipmentDetails>
        </Modal>
      </div>
    );
  }
}

export default withRouter(FulfillOrder)
