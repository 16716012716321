import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import Modal from '../../common/Modal';
import moment from 'moment'
import PlaceOrderOnHold from '../../common/PlaceOrderOnHold';
import Accordion from '../../common/Accordion';
import Pagination from '../../common/Pagination';

class OnHoldList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            siteId: -1,
            accountTypeId: -1,
            pricingType: '-- All --',
            orders: [],
            summaryItems: [],
            ordersToFulfill: [],
            sortBy: "DateTimeStamp",
            ascending: true,
            pageCount: 0,
            pageIndex: 0,
            sortColumns: [],
        }

        this.paramsKey = props.paramsKey ? props.paramsKey : "params.onhold-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handlePricingTypeChange = this.handlePricingTypeChange.bind(this);
        this.handleColCheck = this.handleColCheck.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.autoRefresh = this.autoRefresh.bind(this);
        this.batchSend = this.batchSend.bind(this);
        this.handleOrderHoldChange = this.handleOrderHoldChange.bind(this);
        this.updateOnHold = this.updateOnHold.bind(this);
        this.pageSize = 50;
        this.handlePageChange = this.handlePageChange.bind(this);

        this.timer = setInterval(this.autoRefresh, 60000);
    }

    componentDidMount() {
        this.getOrders();
        this.getSummary();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handlePricingTypeChange(e) {
        this.setState({ pricingType: e.target.value });
    }

    handleColCheck(checked) {
        var rows = document.getElementsByClassName("row-check");
        for (var i = 0; i < rows.length; i++) {
            rows[i].checked = checked;
            this.state.orders[i].checked = checked;
        }
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    handleRowCheck(order, checked) {
        order.checked = checked;
    }

    getOrders(page, e) {
        if (!!e) {
            e.preventDefault();
        }
        if (page === undefined) {
            page = this.state.pageIndex;
        }
        this.setState({ pageIndex: page });
        var sortBy = this.state.sortColumns.map(c => {
            return c.name + " " + c.direction;
        }).join(",");
        var params = {
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            pricingType: this.state.pricingType,
            sortBy: sortBy,
            ascending: this.state.ascending,
            pageSize: this.pageSize,
            pageIndex: page
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Orders/SearchOnHold', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    getSummary() {
        this.http.get('/api/Orders/GetOnHoldSummary', { samplesOnly: true }).then(data => {
            this.setState({ summaryItems: data });
        });
    }

    autoRefresh() {
        // refresh only if there is no selection
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.getOrders();
        }
    }

    batchSend() {
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.utils.warning("Please select orders to send to fulfillment");
        }
        else {
            this.utils.confirm("Are you sure to send the selected orders to fulfillment?", () => {
                var selectedOrders = this.state.orders.filter(o => o.checked);
                var orderIds = [];
                for (var i = 0; i < selectedOrders.length; i++) {
                    orderIds.push(selectedOrders[i].orderId);
                }
                var params = {
                    orders: orderIds.join(",")
                }
                this.http.get('/api/Orders/SendOrdersToFulfillment', params).then(data => {
                    this.getOrders();
                    for (var i = 0; i < selectedOrders.length; i++) {
                        window.open(this.getPrintPDFUrl("SalesOrder", selectedOrders[i]), "_blank");
                    }
                }).catch(() => {
                    this.getOrders();
                });
            });
        }
    }

    sendToFulfillment(order, skipAddressValidation) {
        var params = {
            orderId: order.orderId,
            validateAddress: !skipAddressValidation
        }
        this.http.get('/api/Orders/SendToFulfillment', params).then(data => {
            if (data.success) {
                this.getOrders();
                window.open(this.getPrintPDFUrl("SalesOrder", order), "_blank");
            }
            else {
                if (data.failureReason === "AddressValidation") {
                    this.utils.confirm(data.message, () => {
                        this.sendToFulfillment(true);
                    }, null, "Ignore!", "Cancel");
                }
            }
        });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&orderID=" + order.orderId + "&orderNumber=" + order.orderNumber;
    }

    editOnHold(order) {
        this.editedOrder = order;
        this.setState({
            holdReleaseDate: moment(order.holdReleaseDate).toDate(),
            holdReason: order.holdReason
        })
        window.$('#editOnHoldModal').modal('show');
    }

    checkTemperature(order) {
        window.open(`https://www.accuweather.com/search-locations?query=${order.state} ${order.zip}&target=daily`, "_blank",
            `toolbar=no,scrollbars=yes,resizable=yes,top=${window.innerHeight / 2 - 250},left=${window.innerWidth / 2 - 200},width=400,height=500`)
    }

    updateOnHold() {
        var params = {
            orderId: this.editedOrder.orderId,
            reason: this.orderHold.holdReason,
            releaseDate: this.orderHold.holdReleaseDate,
            validateAddress: false
        }
        this.http.post('/api/Orders/PlaceOnHold', params).then(data => {
            this.getOrders();
            window.$("#editOnHoldModal").modal("hide");
        })
    }

    handleOrderHoldChange(val) {
        this.orderHold = val;
    }

    sortBy(fieldName) {
        var sortColumns = [...this.state.sortColumns];
        var fieldSortIndex = this.state.sortColumns.findIndex(c => c.name === fieldName);
        if (fieldSortIndex >= 0) {
            var fieldSort = sortColumns[fieldSortIndex];
            if (fieldSort.direction === "asc") {
                fieldSort.direction = "desc";
            }
            else {
                sortColumns.splice(fieldSortIndex, 1);
            }
        }
        else {
            var fieldSort = {
                name: fieldName,
                direction: "asc",
            }
            sortColumns.push(fieldSort);
        }
        this.setState({
            sortColumns: [...sortColumns]
        });

        if (fieldName !== this.state.sortBy) {
            this.setState({
                sortBy: fieldName,
            }, () => {
                this.getOrders();
            });
        }
        else {
            this.setState({
                ascending: !this.state.ascending,
            }, () => {
                this.getOrders();
            });
        }
    }

    getSortDirection(fieldName) {
        var fieldSort = this.state.sortColumns.find(c => c.name === fieldName);
        if (fieldSort) {
            var fieldSortIndex = this.state.sortColumns.findIndex(c => c.name === fieldName);
            return fieldSort.direction + " col-" + (fieldSortIndex + 1);
        }
        else {
            return "";
        }
    }

    getSortIndex(fieldName) {
        var fieldSortIndex = this.state.sortColumns.findIndex(c => c.name === fieldName);
        if (fieldSortIndex >= 0) {
            return "index-" + (fieldSortIndex + 1);
        }
        else {
            return "";
        }
    }


    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">{this.props.title ? this.props.title : "Order Holds"}</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.getOrders(0, e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="site">Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="accountType">Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        {!this.props.web &&
                                            <div className="form-group col-md-3">
                                                <label htmlFor="pricingType">Pricing Type</label>
                                                <select className="form-control" value={this.state.pricingType} onChange={this.handlePricingTypeChange}>
                                                    <option value='-- All --'>--All --</option>
                                                    <option value='Sample'>Samples</option>
                                                    <option value='Paid'>Paid</option>
                                                </select>
                                            </div>
                                        }
                                        <div className="form-group col-md-3">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <Accordion title="Sample/Promo Items Summary" icon="flaticon-list" id="samples">
                            <div className="form-row">
                                <table className="table table-striped mt-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.summaryItems.map((item) =>
                                            <tr key={item.name}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.quantity}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>
                        <div className="card">
                            <div className="card-body">
                                <div className="text-right">
                                    <button className="btn btn-default btn-sm" onClick={this.batchSend}>Batch Send To Fulfillment</button>
                                </div>
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <Checkbox onChange={this.handleColCheck}></Checkbox>
                                                </th>
                                                <th scope="col">Order</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Customer Number</th>
                                                <th className={"sortable " + this.getSortDirection("DateTimeStamp")} scope="col" onClick={(e) => this.sortBy("DateTimeStamp")}>Date<span className={"sort-index " + this.getSortIndex("DateTimeStamp")}></span></th>
                                                <th className={"sortable " + this.getSortDirection("StateAbbreviation")} scope="col" onClick={(e) => this.sortBy("StateAbbreviation")}>State<span className={"sort-index " + this.getSortIndex("StateAbbreviation")}></span></th>
                                                <th className={"sortable " + this.getSortDirection("HoldReason")} scope="col" onClick={(e) => this.sortBy("HoldReason")}>Hold Reason<span className={"sort-index " + this.getSortIndex("HoldReason")}></span></th>
                                                <th className={"sortable " + this.getSortDirection("HoldReleaseDate")} scope="col" onClick={(e) => this.sortBy("HoldReleaseDate")}>Expected Release<span className={"sort-index " + this.getSortIndex("HoldReleaseDate")}></span></th>
                                                <th className={"sortable " + this.getSortDirection("TimeInTransit")} scope="col" onClick={(e) => this.sortBy("TimeInTransit")}>Time In Transit<span className={"sort-index " + this.getSortIndex("TimeInTransit")}></span></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td>
                                                        <Checkbox className="row-check" onChange={e => this.handleRowCheck(order, e)}></Checkbox>
                                                    </td>
                                                    <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td>{order.companyName}</td>
                                                    <td>{order.customerNumber}</td>
                                                    <td>{order.dateTimeStamp}</td>
                                                    <td>{order.state}</td>
                                                    <td>{order.holdReason}</td>
                                                    <td>{order.holdReleaseDate}</td>
                                                    <td>{order.timeInTransit}</td>
                                                    <td>
                                                        <button type="button" title="check temperature" className="btn btn-xs btn-info" onClick={(e) => this.checkTemperature(order)}><i className="fa-solid fa-temperature-low"></i></button>
                                                        <button type="button" title="edit" className="btn btn-xs btn-default ml-2" onClick={(e) => this.editOnHold(order)}><i className="fas fa-pen"></i></button>
                                                        <button type="button" className="btn btn-xs btn-secondary ml-2" onClick={(e) => this.sendToFulfillment(order)}>Send to Fulfillment</button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal height={500} id="editOnHoldModal" title="Order Hold" onOk={this.updateOnHold}>
                    <PlaceOrderOnHold holdReason={this.state.holdReason} holdReleaseDate={this.state.holdReleaseDate} onChange={this.handleOrderHoldChange}></PlaceOrderOnHold>
                </Modal>
            </div>
        );
    }
}

export default OnHoldList;
