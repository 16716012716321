import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';
import Checkbox from './Checkbox';

class ProductTree extends Component {

    constructor(props) {
        super(props);

        var selectedProducts = this.props.value ? this.props.value : [];

        console.log(props);

        this.state = {
            selectedProducts: selectedProducts,
            showInactive: false
        };
        this.http = new HttpRequestService();
        this.handleShowInactiveChange = this.handleShowInactiveChange.bind(this);
        this.getProductTree = this.getProductTree.bind(this);
    }

    componentDidMount() {
        this.getProductTree();
    }

    componentDidUpdate(prevProps) {
        if (this.props.companyId !== prevProps.companyId || this.props.productTypeId !== prevProps.productTypeId) {
            this.getProducts();
        }
    }

    getProductTree() {
        var params = {
            activeOnly: !this.state.showInactive
        };
        this.http.get('/api/Inventory/GetProductTree', params).then(data => {
            var tree = data;
            var treeData = [];
            for (var i = 0; i < tree.companies.length; i++) {
                var company = tree.companies[i];
                var companyNode = {
                    id: "company_" + company.id,
                    text: company.companyName,
                    children: []
                };

                for (var j = 0; j < company.productTypes.length; j++) {
                    var productType = company.productTypes[j];
                    var productTypeNode = {
                        id: companyNode.id + "_productType_" + productType.id,
                        text: productType.type,
                        children: []
                    }

                    for (var k = 0; k < productType.products.length; k++) {
                        var product = productType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        productTypeNode.children.push(productNode);
                    }

                    companyNode.children.push(productTypeNode);
                }

                treeData.push(companyNode);
            }

            window.$("#productsTree").jstree("destroy");

            window.$('#productsTree')
                .on('changed.jstree', (e, data) => {
                    this.setState({
                        selectedProducts: data.selected
                    }, this.notifyChange)                 
                })
                .jstree({
                    "core": {
                        "data": treeData,
                    },
                    "plugins": ["checkbox"],

                });

            setTimeout(() => {
                var selectedProducts = this.state.selectedProducts;
                for (var i = 0; i < selectedProducts.length; i++) {
                    window.$("#productsTree").jstree('select_node', selectedProducts[i]);
                }
            }, 500);
        });
    }

    handleShowInactiveChange(checked) {
        this.setState({ showInactive: checked }, this.getProductTree);
    }

    notifyChange() {
        if(this.props.onChange){
            this.props.onChange(this.state.selectedProducts);
        }
    }

    render() {
        return (
            <>
                <Checkbox label="Show Inactive" checked={this.state.showInactive} onChange={this.handleShowInactiveChange}></Checkbox>
                <div id="productsTree"></div>
            </>
        );
    }
}
export default ProductTree