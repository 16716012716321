import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import PartialLoading from '../../common/PartialLoading';
import {scaleSequential} from 'd3-scale';
import {interpolateRainbow} from 'd3-scale-chromatic/dist/d3-scale-chromatic';

class SalesTrend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: "",
            subtitle: ""
        }
        this.chart = null;
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getData = this.getData.bind(this);
        this.canvas = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var params = {
            type: this.props.type
        }
        this.setState({ loading: true });
        this.http.getSilent("/api/Report/GetSalesTrend", params).then(data => {
            this.renderChart(data);
            this.setState({
                loading: false,
                title: this.props.title,
                subtitle: data.description
            });
        });
    }

    renderChart(data) {
        if (this.chart !== null) {
            this.chart.destroy();
        }
        if (data.length === 0) {
            return;
        }

        var labels = [];
        var datasets = [];
        for (let i = 0; i < data.groups.length; i++) {
            var color = scaleSequential(interpolateRainbow)(i / data.groups.length);
            labels = data.groups[i].items.map(item => { return item.monthName });
            datasets.push({
                label: data.groups[i].name,
                fill: false,
                backgroundColor: color,
                borderColor: color,
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0,
                pointBorderColor: color,
                pointBackgroundColor: color,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: color,
                pointHoverBorderColor: color,
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 5,
                lineTension: 0,
                data: data.groups[i].items.map((item) => { return item.totalRevenue })
            });
        }

        var ctx = this.canvas.current.getContext('2d');
        this.chart = new window.Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                animation: {
                    easing: "easeInOutBack"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                if (parseInt(value) >= 1000) {
                                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return '$' + value;
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var dataLabel = data.datasets[tooltipItem.datasetIndex].label;
                            var value = ': $ ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                            if (window.Chart.helpers.isArray(dataLabel)) {
                                dataLabel = dataLabel.slice();
                                dataLabel[0] += value;
                            } else {
                                dataLabel += value;
                            }
                            return dataLabel;
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <PartialLoading visible={this.state.loading}></PartialLoading>
                <div className="card">
                    <div className="card-header">
                        <div className="card-head-row">
                            <div className="card-title">{this.state.title}</div>
                            <div className="card-tools">
                                <button className="btn btn-icon btn-link mr-2" onClick={this.getData}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-category">{this.state.subtitle}</div>
                    </div>
                    <div className="card-body pb-0">
                        <canvas ref={this.canvas} style={{ minHeight: 375 }}></canvas>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default SalesTrend