import React, { Component } from 'react';
import SalesTrend from '../dashboard/SalesTrend';

class SalesTrendsReport extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-inner">
          <div className="page-header">
            <h4 className="page-title">Invoice Report</h4>
          </div>
          <div className="row">
            <div className="col-md-8">
              <SalesTrend type="ByCompany" title="Monthly Sales By Company"></SalesTrend>
            </div>
            <div className="col-md-8">
              <SalesTrend type="ByChannel" title="Monthly Sales By Channel"></SalesTrend>
            </div>
            <div className="col-md-8">
              <SalesTrend type="ByProduct" title="Monthly Sales By Product (Top 20)"></SalesTrend>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SalesTrendsReport;
