import React, { Component } from 'react';
import RadioGroup from '../../common/RadioGroup';
import Checkbox from '../../common/Checkbox';
import Select from 'react-select';

class UserDefinedField extends Component {
    constructor(props) {
        super(props);
        var entities = [];
        if (props.field && props.field.entities) {
            entities = props.field.entities.map((value, index) => {
                return { value: value.entity, label: value.entity }
            });
        }
        this.state = {
            name: props.field ? props.field.name : "",
            position: props.field ? props.field.position : 0,
            selection: props.field ? props.field.selection : "Single",
            filterCriteria: props.field ? props.field.filterCriteria : false,
            entities: entities
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleFilterCriteriaChange = this.handleFilterCriteriaChange.bind(this);
        this.handleEntitiesChange = this.handleEntitiesChange.bind(this);
        this.notifyChange();
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        }, this.notifyChange);
    }

    handlePositionChange(e) {
        this.setState({
            position: e.target.value
        }, this.notifyChange);
    }

    handleSelectionChange(value) {
        this.setState({
            selection: value
        }, this.notifyChange);
    }

    handleFilterCriteriaChange(value) {
        this.setState({
            filterCriteria: value
        }, this.notifyChange);
    }

    handleEntitiesChange(value) {
        this.setState({
            entities: value
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        var entityOptions = [
            {
                label: "Account",
                value: "Account",
            },
            {
                label: "Contact",
                value: "Contact",
            },
            {
                label: "Category",
                value: "Category",
            }
        ]
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Name</label>
                        <input type="text" value={this.state.name} onChange={this.handleNameChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Position</label>
                        <input type="text" value={this.state.position} onChange={this.handlePositionChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Selection</label>
                        <RadioGroup groupName="selection" value={this.state.selection} items={[{ value: "Single", text: "Single" }, { value: "Multiple", text: "Multiple" }]} onChange={this.handleSelectionChange}></RadioGroup>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Filter Criteria?</label>
                        <Checkbox checked={this.state.filterCriteria} onChange={this.handleFilterCriteriaChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Applies to</label>
                        <Select options={entityOptions} isMulti value={this.state.entities} onChange={this.handleEntitiesChange}></Select>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserDefinedField