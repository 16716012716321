import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';
import Select from 'react-select';

class StateMultipleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.getStates = this.getStates.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getStates();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryID !== this.props.countryID) {
            this.getStates();
        }
    }

    getStates() {
        this.http.get('/api/Customers/GetStates', { countryID: this.props.countryID ? this.props.countryID : 211 }).then(data => {
            this.setState({ items: data });
            if (data.length > 0 && this.props.value === 0) {
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        if (e) {
            this.props.onChange(e.map((i) => { return i.value }));
        }
        else {
            this.props.onChange([]);
        }
    }

    render() {
        console.log(this.props.value);
        var options = this.state.items.map((value, index) => {
            const selected = this.props.value.indexOf(value.stateID) >= 0;
            return { label: value.name, value: value.stateID, selected: selected };
        });

        var selectedOptions = options.filter(o => o.selected === true);

        return (
            <Select isClearable={true} options={options} isMulti={true} value={selectedOptions} onChange={this.handleChange}></Select>
        );
    }
}
export default StateMultipleSelect
