import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import UserInformation from './UserInformation';
import { HttpRequestService } from '../../services/HttpRequestService';

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleUserInformationChange = this.handleUserInformationChange.bind(this);
        this.createUser = this.createUser.bind(this);
    }

    handleUserInformationChange(userInformation) {
        this.userInformation = userInformation;
    }

    createUser() {
        var params = this.userInformation

        this.http.get('/api/Users/CreateUser', { model: params }).then(data => {
            this.props.history.push('/user/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create User</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">User Information</div>
                            </div>
                            <div className="card-body">
                                <UserInformation onChange={this.handleUserInformationChange}></UserInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createUser}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddUser)