import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Report from '../../common/Report';
import Checkbox from '../../common/Checkbox';
import CriteriaBuilder from '../../common/CriteriaBuilder';
import Pagination from '../../common/Pagination';
import fileDownload from 'js-file-download';

class AccountList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var allColumns = [
            { label: "Account Name", fieldName: "name", type: "standard" },
            { label: "Account Number", fieldName: "accountNumber", type: "standard" },
            { label: "Account Type", fieldName: "accountTypeStr", type: "standard" },
            { label: "First Name", fieldName: "primaryContactFirstName", type: "standard" },
            { label: "Last Name", fieldName: "primaryContactLastName", type: "standard" },
            { label: "Phone Number", fieldName: "phoneNumberStr", type: "standard" },
            { label: "Fax Number", fieldName: "faxNumberStr", type: "standard" },
            { label: "Email", fieldName: "email", type: "standard" },
            { label: "Billing Address Business/Company", fieldName: "billingAddressCompany", type: "standard" },
            { label: "Billing Address Contact", fieldName: "billingContactName", type: "standard" },
            { label: "Billing Address Line 1", fieldName: "billingAddress1", type: "standard" },
            { label: "Billing Address Line 2", fieldName: "billingAddress2", type: "standard" },
            { label: "Billing Address City", fieldName: "billingCity", type: "standard" },
            { label: "Billing Address Zip", fieldName: "billingZip", type: "standard" },
            { label: "Billing Address Residential/Business", fieldName: "billingIsResidential", type: "standard" },
            { label: "Billing Address State", fieldName: "billingState", type: "standard" },
            { label: "Full Billing Address", fieldName: "fullBillingAddress", type: "standard" },
            { label: "Shipping Address Business/Company", fieldName: "shippingAddressCompany", type: "standard" },
            { label: "Shipping Address Contact", fieldName: "shippingContactName", type: "standard" },
            { label: "Shipping Address Line 1", fieldName: "shippingAddress1", type: "standard" },
            { label: "Shipping Address Line 2", fieldName: "shippingAddress2", type: "standard" },
            { label: "Shipping Address City", fieldName: "shippingCity", type: "standard" },
            { label: "Shipping Address Zip", fieldName: "shippingZip", type: "standard" },
            { label: "Shipping Address Residential/Business", fieldName: "shippingIsResidential", type: "standard" },
            { label: "Shipping Address State", fieldName: "shippingState", type: "standard" },
            { label: "Full Shipping Address", fieldName: "fullShippingAddress", type: "standard" },
            { label: "Last Shipped", fieldName: "lastShipped", type: "standard" },
        ];

        this.availableCriterias = [
            { label: "Account Name", name: "name", type: "standard", operators: ["equal", "contains words", "starts with"] },
            { label: "Account Type", name: "account_type", type: "standard", operators: ["equal"], selector: "AccountTypesSelect" },
            { label: "Account Number", name: "account_number", type: "standard", operators: ["equal", "starts with"] },
            { label: "Phone/Fax Number", name: "phone_number", type: "standard", operators: ["equal", "starts with"] },
            { label: "Email", name: "email", type: "standard", operators: ["equal", "starts with"] },
            { label: "Web Username", name: "web_username", type: "standard", operators: ["equal", "starts with"] },
            { label: "State", name: "state", type: "standard", operators: ["equal"], selector: "StateSelect" },
            { label: "City", name: "city", type: "standard", operators: ["equal", "starts with"] },
            { label: "Zip Code", name: "zip_code", type: "standard", operators: ["equal", "starts with"] },
            { label: "Residential/Business", name: "isResidential", type: "standard", operators: ["equal"], selector: "IsResidentialSelect" },
            { label: "Creation Date", name: "creation_date", type: "standard", operators: ["between"], selector: "DateRange" },
            { label: "Last Order Date", name: "last_order_date", type: "standard", operators: ["between"], selector: "DateRange" },
            { label: "Ordered Products", name: "ordered_products", type: "standard", operators: ["include any"], selector: "ProductTree" },
        ];

        var selectedColumns = allColumns.slice(0, 5);
        this.defaultCriteria = { fieldName: "name", operator: "like", value: "" };
        var initialState = {
            criteriaBuilder: {
                logicalOperator: "AND",
                groups: [
                    {
                        logicalOperator: "AND",
                        criterias: [{ fieldName: "name", operator: "like", value: "" }]
                    }]
            },
            accounts: [],
            selectedColumns: selectedColumns,
            allColumns: allColumns,
            activeOnly: false,
            includeContacts: false,
            anySelection: false,
            queryId: 0,
            pageCount: 0,
            pageIndex: 0,
            reportReady: false,
        }

        if (this.props.match.path.indexOf("active-accounts-list") >= 0) {
            initialState.activeOnly = true;
        }

        this.pageSize = 200;
        this.sortBy = "";
        this.sortDirection = "ASC";
        this.paramsKey = "params.account-list";
        if (this.props.location.state && this.props.location.state.loadParams) {
            this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        }
        else {
            this.state = this.utils.prepareSessionState(initialState, "");
        }
        this.http = new HttpRequestService();

        this.handleBuilderChange = this.handleBuilderChange.bind(this);
        this.handleColumnsSelected = this.handleColumnsSelected.bind(this);
        this.handleReportDataLoaded = this.handleReportDataLoaded.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handleIncludeContactsChange = this.handleIncludeContactsChange.bind(this);
        this.handleColCheck = this.handleColCheck.bind(this);
        this.getAccounts = this.getAccounts.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.cancelSearch = this.cancelSearch.bind(this);
        this.batchDelete = this.batchDelete.bind(this);
        this.batchArchive = this.batchArchive.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    componentDidMount() {
        this.getUserDefinedFields();
        if (this.props.match.params.reportId) {
            var params = {
                id: this.props.match.params.reportId
            }
            this.http.get('/api/Report/GetReportData', params).then(data => {
                this.handleReportDataLoaded(data.params);
                this.setState({
                    saveAs: data.reportName,
                    reportReady: true
                });
            });
        }
        else {
            if (this.props.location.state && this.props.location.state.loadParams) {
                this.getAccounts();
            }
            this.setState({
                reportReady: true
            });
        }
    }

    getUserDefinedFields() {
        var params = {
            entity: "Account"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            var allColumns = this.state.allColumns;
            for (var i = 0; i < data.length; i++) {
                allColumns.push({
                    label: data[i].name,
                    fieldName: data[i].userDefinedFieldID.toString(),
                    type: "user_defined"
                });
            }

            this.setState({
                allColumns: allColumns
            });
        });
    }

    handleColumnsSelected(e) {
        var selectedColumns = [];
        for (var i = 0; i < e.length; i++) {
            selectedColumns.push(this.state.allColumns.find(c => c.fieldName === e[i].fieldName));
        }
        this.sortBy = "";
        this.setState({
            accounts: [],
            selectedColumns: selectedColumns,
        }, this.getAccounts);
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    handleIncludeContactsChange(checked) {
        this.setState({ includeContacts: checked });
    }

    handlePageChange(page) {
        this.getAccounts(null, page);
    }

    exportToExcel() {
        if (this.state.accounts && this.state.accounts.length > 0) {
            var selectedColumns = this.state.selectedColumns.map((value, index) => {
                if (value.type === "standard") {
                    return value.fieldName;
                }
                else {
                    return "udf_" + value.fieldName;
                }
            });
            var columnHeaders = this.state.selectedColumns.map((value, index) => {
                return value.label;
            });
            var params = {
                filter: JSON.stringify(this.state.criteriaBuilder),
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                activeOnly: this.state.activeOnly,
                includeContacts: this.state.includeContacts,
                selectedColumns: selectedColumns.join(','),
                columnHeaders: columnHeaders.join(','),
                pageSize: this.pageSize,
                pageIndex: -1
            }
            this.http.get('/api/Crm/ExportAccounts', params, false, "blob").then(data => {
                if (data.type === "text/plain") {
                    this.utils.info("It will take a while to export this report. PowerD will send you an email when it is ready.");
                }
                else {
                    fileDownload(data, "account.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                }

            }).catch(() => {
                this.setState({ loading: false });
            });
        }
    }

    getAccounts(e, page) {
        if (!page) {
            page = 0;
        }
        this.setState({ pageIndex: page });
        if (!!e) {
            e.preventDefault();
        }
        var selectedColumns = this.state.selectedColumns.map((value, index) => {
            if (value.type === "standard") {
                return value.fieldName;
            }
            else {
                return "udf_" + value.fieldName;
            }
        });
        var params = {
            filter: JSON.stringify(this.state.criteriaBuilder),
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            activeOnly: this.state.activeOnly,
            includeContacts: this.state.includeContacts,
            selectedColumns: selectedColumns.join(','),
            pageSize: this.pageSize,
            pageIndex: page
        }
        var sessionState = {
            criteriaBuilder: this.state.criteriaBuilder,
            selectedColumns: this.state.selectedColumns,
            activeOnly: this.state.activeOnly
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(sessionState));
        this.http.get('/api/Crm/SearchAccounts2', params, false).then(data => {
            this.setState({ accounts: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        }).catch(() => {
            this.setState({ loading: false });
        });
    }

    cancelSearch() {
        this.http.cancel();
    }

    getFieldValue(account, column) {
        if (column.type === "standard") {
            return account[column.fieldName];
        }
        else {
            var userDefinedField = account.userDefinedFields.find(f => f.userDefinedFieldID === parseInt(column.fieldName));
            if (userDefinedField) {
                return userDefinedField.valueStr;
            }
        }
        return "";
    }

    getSaveParams() {
        return {
            criteriaBuilder: this.state.criteriaBuilder,
            selectedColumns: this.state.selectedColumns,
        }
    }

    handleReportDataLoaded(params) {
        var data = JSON.parse(params);
        this.setState({
            queryId: this.state.queryId + 1,
            criteriaBuilder: data.criteriaBuilder,
            selectedColumns: data.selectedColumns,
        }
        )
    }

    handleBuilderChange(val) {
        this.setState({
            criteriaBuilder: val
        });
    }

    handleColCheck(checked) {
        var rows = document.getElementsByClassName("row-check");
        for (var i = 0; i < rows.length; i++) {
            rows[i].checked = checked;
            this.state.accounts[i].checked = checked;
        }

        this.setState({
            anySelection: checked
        })
    }

    handleRowCheck(account, checked) {
        account.checked = checked;
        this.setState({
            anySelection: this.state.accounts.findIndex(a => a.checked) >= 0
        })
    }

    batchDelete() {
        if (this.state.accounts.findIndex(a => a.checked) < 0) {
            this.utils.warning("Please select accounts to be deleted");
        }
        else {
            this.utils.confirm("Are you sure you want to delete selected accounts?", () => {
                var selectedAccounts = this.state.accounts.filter(a => a.checked);
                var accountIds = [];
                for (var i = 0; i < selectedAccounts.length; i++) {
                    accountIds.push(selectedAccounts[i].accountID);
                }
                var params = {
                    accounts: accountIds.join(",")
                }
                this.http.post('/api/Crm/DeleteAccounts', params).then(data => {
                    this.getAccounts();
                });
            });
        }
    }

    batchArchive() {
        if (this.state.accounts.findIndex(a => a.checked) < 0) {
            this.utils.warning("Please select accounts to be archived");
        }
        else {
            this.utils.confirm("Are you sure to archive selected accounts?", () => {
                var selectedAccounts = this.state.accounts.filter(a => a.checked);
                var accountIds = [];
                for (var i = 0; i < selectedAccounts.length; i++) {
                    accountIds.push(selectedAccounts[i].accountID);
                }
                var params = {
                    accounts: accountIds.join(",")
                }
                this.http.post('/api/Crm/ArchiveAccounts', params).then(data => {
                    this.getAccounts();
                });
            });
        }
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getAccounts();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Account List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={(e) => this.getAccounts(e)}>
                            <div className="form-row">
                                <CriteriaBuilder
                                    onChange={this.handleBuilderChange}
                                    availableCriterias={this.availableCriterias}
                                    defaultCriteria={this.defaultCriteria}
                                    criteriaBuilder={this.state.criteriaBuilder}
                                    queryId={this.state.queryId}
                                ></CriteriaBuilder>
                            </div>
                            <div className="card form-row">
                                <div className="form-group col-md-12">
                                    {!this.state.loading &&
                                        <button type="submit" className="btn btn-primary pull-right">Submit</button>
                                    }
                                    {this.state.loading &&
                                        <button type="button" className="btn btn-primary pull-right" onClick={this.cancelSearch}>Cancel Search</button>
                                    }
                                    <div className="pull-right mr-1">
                                        <Checkbox className="pull-right" label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                    </div>
                                    <div className="pull-right mr-1">
                                        <Checkbox className="pull-right" label="Include Contacts" checked={this.state.includeContacts} onChange={this.handleIncludeContactsChange}></Checkbox>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="card">
                            <div className="card-body">
                                {this.state.anySelection &&
                                    <div className="form-row">
                                        <div className="col-md-12 pr-4">
                                            <button className="btn btn-default btn-sm pull-right" onClick={this.batchArchive}>Batch Archive</button>
                                            <button className="btn btn-default btn-sm pull-right mr-2" onClick={this.batchDelete}>Batch Delete</button>
                                        </div>
                                    </div>
                                }
                                {this.state.reportReady &&
                                    <Report name="AccountList" hasData={this.state.accounts && this.state.accounts.length > 0}
                                        allowColumnSelection={true}
                                        availableColumns={this.state.allColumns}
                                        onColumnsSelected={this.handleColumnsSelected}
                                        allowSave={true}
                                        saveAs={this.state.saveAs}
                                        saveParams={this.getSaveParams()}
                                        saveAsType="AccountList"
                                        onReportDataLoad={this.handleReportDataLoaded}
                                        onExportReportToExcel={this.exportToExcel}
                                    >
                                        <div className="form-row">
                                            <table className="table table-striped mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="noprint" scope="col">
                                                            <Checkbox onChange={this.handleColCheck}></Checkbox>
                                                        </th>
                                                        {this.state.selectedColumns.length > 0 && this.state.selectedColumns.map(column =>
                                                            <th key={column.fieldName} scope="col" className="sortable" onClick={(e) => this.sortReport(column.type === "standard" ? column.fieldName : "udf_" + column.fieldName)}>{column.label}</th>
                                                        )}
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.accounts.map((account, index) =>
                                                        <tr key={index}>
                                                            <td className="noprint">
                                                                <Checkbox className="row-check" onChange={e => this.handleRowCheck(account, e)}></Checkbox>
                                                            </td>
                                                            {this.state.selectedColumns.length > 0 && this.state.selectedColumns.map((column, index) =>
                                                                <td key={column.fieldName}>
                                                                    {index === 0 &&
                                                                        <Link to={{ pathname: "/crm/account/" + account.accountID, state: { displayBackButton: true, searchUrl: "/crm/account-list" } }}><span dangerouslySetInnerHTML={{ __html: this.getFieldValue(account, column) }}></span></Link>
                                                                    }
                                                                    {index !== 0 &&
                                                                        <span dangerouslySetInnerHTML={{ __html: this.getFieldValue(account, column) }}></span>
                                                                    }
                                                                </td>
                                                            )}
                                                            <td></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-row noprint">
                                            <div className="col-md-12">
                                                {this.state.accounts.length > 0 &&
                                                    <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                                }
                                            </div>
                                        </div>
                                    </Report>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(AccountList);
